<template>
  <div class="main">

    <div class="main-mengban" v-if="mengban"></div>

    <div class="home-header">

      <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/LOGO2.png" class="logo-img" />
      <div class="home-center">
        <div class="home-nav" :class="{ 'active': selectIndex == 0 }" @click="selectItem(0)">
          首页
        </div>
        <!-- <div class="home-nav" @click="selectItem(1)" :class="{ 'active': selectIndex == 1 }">
                    常见问题
                </div> -->
        <!-- <div class="home-nav" @click="selectItem(2)" :class="{ 'active': selectIndex == 2 }">
          对话慧言AI
        </div> -->
        <div class="home-nav" @click="selectItem(2)" :class="{ 'active': selectIndex == 2 }">
          AI员工训练平台
        </div>
        <div class="home-nav" @click="selectItem(3)" :class="{ 'active': selectIndex == 3 }">
          <img src="https://cdn.wiseai.chat/APP/sys_img/aiTeacher/privateDomain-logo.png" alt="">
          私域运营平台
        </div>
        <div class="home-nav" @click="selectItem(4)" :class="{ 'active': selectIndex == 4 }">
          <img src="https://cdn.wiseai.chat/APP/sys_img/digitalEmployee/HRrecruit-logo.png" alt="">
          AI招聘平台
        </div>
        <div class="home-nav" @click="selectItem(5)" :class="{ 'active': selectIndex == 5 }">
          帮助中心
        </div>
      </div>
      <div class="home-right">
        <div class="free-use-btn" @click="openWork">
          登录
        </div>
        <!-- <div class="free-use-btn" @click="openWork" v-if="access_token != null && access_token != ''">
          进入
        </div>

        <div class="free-use-btn" v-else @click="openLogin">
          免费使用
        </div> -->

      </div>

    </div>
    <keep-alive>
      <router-view ref="child" @updataIndexAction="updateIndex" @openloginAction="openlogin2" />
    </keep-alive>

    <Loginbox ref="childLogin" @refreshModelAction="refreshModel"></Loginbox>
  </div>
</template>

<script>
import common from "common/js/common";
import Loginbox from "components/loginbox/loginbox.vue";
export default {
  name: "app",
  components: {
    Loginbox,

  },
  data () {
    return {

      selectIndex: 0,
      mengban: true,
    };
  },
  computed: {

    access_token () {
      return this.$store.state.user.access_token
    },
    userName () {
      return this.$store.state.user.userName
    },
    headImgUrl () {
      return this.$store.state.user.headImgUrl
    },


  },

  methods: {
    selectItem (index) {
      if (this.selectIndex == index) return;

      if (index == 0) {
        this.selectIndex = index;
        if (this.$route.path != '/') {
          this.$router.replace({ path: '/' })
        }
        sessionStorage.setItem('main_index', 0)
        this.jumbTop();

      } else if (index == 1) {
        if (this.$route.path != '/') {
          this.$router.replace({ path: '/' })
        }
        this.$refs.child.jumbQuestion()
        sessionStorage.setItem('main_index', 1)
      } else if (index == 2) {
        // this.$router.replace({ path: '/model' })
        // sessionStorage.setItem('main_index', 2)
        // AI员工训练平台
        window.open(this.$currentServe + "backend/#/");
      } else if (index == 3) {
        // 私域运营平台
        window.open(this.$currentServe + 'wxassistant');
        // sessionStorage.setItem('main_index', 3)
      } else if (index == 4) {
        // AI招聘平台
        window.open(this.$currentServe + 'HRrecruit');
        // sessionStorage.setItem('main_index', 4)
      } else if (index == 5) {
        // 帮助中心
        window.open('https://doc.wiseai.chat');
        // sessionStorage.setItem('main_index', 5)
      }
    },
    openWork () {
      // location.href = this.$currentServe + "backend/#/";
      window.open(this.$currentServe + "crm")
    },
    updateIndex () {
      this.selectIndex = 2;
    },
    openlogin2 (index, id) {

      this.$refs.childLogin.showLoginDialog(index, id)

    },
    refreshModel (index, id) {
      console.log("33333333333");
      this.$refs.child.refreshModel2(index, id);
    },
    jumbTop () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    openLogin () {
      this.$refs.childLogin.showLoginDialog(3, null)
    },
    logout () {



      this.$localstore.remove('access_token')
      this.$store.commit('user/changeToken', null);
      this.$localstore.remove('hdUserId')
      this.$localstore.remove('nickname')
      this.$store.commit('user/changeuserName', '');
      this.$localstore.remove('headImgUrl')
      this.$store.commit('user/changeheadImgurl', '');

    }

  },

  created () {

    if (window.screen.availWidth <= 1000) {
      window.location.replace(this.$currentServe + "mobile/#/home");
      return;
    } else {
      this.mengban = false;
    }

    if (sessionStorage.getItem('main_index') == null) {

      this.selectIndex = 0;
    } else {
      this.selectIndex = sessionStorage.getItem('main_index')
    }
  },
  mounted () {

  }
};
</script>

<style scoped lang="less">
.el-popover.my-popover {
  padding: 0;
  background: rgba(0, 0, 0, 0);
  border: 0;
}

.main {
  position: relative;
  min-width: 1200px;
  overflow: auto;
}

.main-mengban {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: 222;
}

.home-header {
  // z-index: 99;
  width: 100%;
  height: 56px;
  background: #ffffff;
  box-shadow: 0px 1px 1px 0px rgba(9, 56, 128, 0.06);
  // opacity: 1;

  // position: fixed;
  // top: 0;
  // left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.logo-img {
  width: 102px;
  height: 38px;
  margin-left: 34px;
  cursor: pointer;
  // position: absolute;
  // left: 0;
}

.home-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-nav {
  line-height: 56px;

  cursor: pointer;
  font-size: 14px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 400;
  color: #999999;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  align-items: center;

  img {
    width: 22px;
    height: 22px;
    margin-right: 4px;
  }
}

.active {
  color: #226cff;
  font-weight: 600;
}

.home-right {
  // position: absolute;
  // right: 0;
  margin-right: 25px;
}

.home-right-person {
  display: flex;
  align-items: center;
}

.home-ps-p {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 14px;
  margin-right: 6px;
}

.home-ps-img {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  opacity: 1;
  border: 1px solid rgba(0, 0, 0, 0.03);
  cursor: pointer;
}

.login-btn {
  width: 108px;
  height: 36px;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 36px;
  margin-right: 10px;
  /* background-color: red; */
  cursor: pointer;
  text-align: center;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  border: 1px solid #e6ebf3;
}

.free-use-btn {
  width: 108px;
  height: 36px;
  background: linear-gradient(270deg, #226cff 0%, #0688ff 100%);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 36px;

  cursor: pointer;
}

::-webkit-scrollbar {
  // width: 0 !important;
}

.div-rightbox {
  display: flex;
  flex-direction: column;
}

.div-logout {
  width: 200px;
  height: 42px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #e6ebf3;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
  line-height: 42px;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
}

.div-logout:hover {
  background: rgba(236, 245, 255, 1);
}
</style>
